@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@layer components {
  /* Headings with Responsive Sizes */
  h1 {
    @apply text-3xl  md:text-5xl lg:text-6xl font-extrabold leading-tight text-secondary-900;
  }
  h2 {
    @apply text-2xl  md:text-4xl lg:text-5xl font-bold leading-tight text-secondary-900;
  }
  h3 {
    @apply text-xl  md:text-3xl lg:text-4xl font-bold leading-snug text-secondary-900;
  }
  h4 {
    @apply text-xl md:text-2xl font-semibold leading-snug text-secondary-900;
  }
  h5 {
    @apply text-base md:text-xl lg:text-2xl font-semibold leading-snug text-secondary-900;
  }
  h6 {
    @apply text-sm md:text-lg lg:text-xl font-medium leading-snug text-secondary-900;
  }

  /* Button */
  .btn {
    @apply inline-flex items-center justify-center px-4 py-2 border border-secondary-300 text-sm font-medium rounded-md text-white bg-secondary-950 hover:bg-secondary-900 active:bg-secondary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center px-4 py-2 border border-secondary-300 text-sm font-medium rounded-md text-secondary-700 bg-white hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500;
  }

  .btn-danger {
    @apply px-2 py-3 bg-red-500 hover:bg-red-700 active:bg-red-800 text-white rounded-md shadow-md;
  }

  /* Product Grid */
  .product-grid {
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-6 items-center;
  }

  /* Input Fields */
  .input {
    @apply block w-full px-3 py-2 border border-secondary-300 rounded-md shadow-sm placeholder-secondary-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm md:text-base;
  }
}
